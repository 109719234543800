@use "@styles/color.module.scss";
@use "@styles/mix.scss";
@use "sass:map";

@each $name, $color in color.$colorMap {
  .borderColor_#{$name} {
    border: 1px solid $color;
  }
}

@each $name, $shadowProps in mix.$shadowMap {
  .shadow_#{$name} {
    @include mix.shadow($shadowProps...);
  }
}

@each $name, $color in (color.$colorMap) {
  .backgroundColor_#{$name} {
    background-color: $color;
  }
}

@each $name, $color in (color.$gradiMap) {
  .backgroundColor_#{$name} {
    background: $color;
  }
}

@each $name, $transparentProps in mix.$transparentMap {
  .backgroundColor_#{$name} {
    @include mix.transparent($transparentProps...);
  }
}
