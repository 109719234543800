@use "@styles/color.module.scss";
@use "@styles/mix.scss";

@mixin modal {
  padding: 24px 16px 16px;
  border-radius: 16px;
  background-color: color.$white;
}

.container {
  @include mix.mediaMobile {
    @include modal;

    position: fixed;
    right: 16px;
    bottom: 16px;
    left: 16px;

    .innerContainer {
      @include mix.gridRow($gap: 24px);
    }
  }

  @include mix.mediaDesktop {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .innerContainer {
      @include modal;
      @include mix.gridRow($gap: 24px);

      width: 360px;
    }
  }
}

.title {
  text-align: center;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.buttons {
  display: flex;

  .button + .button {
    margin-left: 8px;
    padding-right: 8px;
    padding-left: 8px;
  }
}
