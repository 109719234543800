$headerHeight: 56px;
$footerHeight: 120px;
$appHeightWhenDesktop: 812px;
$appWidthWhenDesktop: 400px;
$toastTransitionCount: 0.3;
$modalTransitionCount: 0.3;
$profileDescriptionLineClamp: 4;
$listContentBottomPadding: 108px;
$mediaMobileWidth: 500px;
$desktopHeaderHeight: 80px;

/* stylelint-disable */
:export {
  headerHeight: $headerHeight;
  footerHeight: $footerHeight;
  appHeightWhenDesktop: $appHeightWhenDesktop;
  appWidthWhenDesktop: $appWidthWhenDesktop;
  toastTransitionCount: $toastTransitionCount;
  modalTransitionCount: $modalTransitionCount;
  profileDescriptionLineClamp: $profileDescriptionLineClamp;
  listContentBottomPadding: $listContentBottomPadding;
  mediaMobileWidth: $mediaMobileWidth;
}
