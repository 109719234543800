@use "@styles/color.module.scss";
@use "@styles/mix.scss";
@use "sass:map";

.common {
  color: color.$black;
  overflow-wrap: anywhere;
  white-space: pre-wrap;

  strong {
    font-weight: 700;
  }
}

@each $name, $fontProps in mix.$fontMap {
  .font_#{$name} {
    @include mix.font($fontProps...);
  }
}

@each $name, $color in color.$colorMap {
  .color_#{$name} {
    color: $color;
  }
}
