// if add new color, should update @global/types.ts Color type.

@use "sass:map";

$colorMap: (
  "primary200": #7856ff,
  "primary100": #9382ff,
  "primary50": #d8d1fe,
  "primary20": #e9e5ff,
  "primary10": #f5f6ff,
  "secondary200": #64a996,
  "secondary100": #82f0be,
  "secondary50": #c8ffe6,
  "secondary20": #e6fbf0,
  "secondary10": #f1fff8,
  "black": #303030,
  "grey100": #909090,
  "grey50": #d8d8d8,
  "grey20": #f6f6f6,
  "white": #fff,
  "lavender200": #7856ff,
  "lavender100": #aa9cff,
  "lavender50": #cfc7ff,
  "lavender20": #e9e5ff,
  "lavender10": #f5f6ff,
  "purple200": #8c3cdc,
  "purple100": #c878ff,
  "purple50": #e6beff,
  "purple20": #f0dcff,
  "purple10": #faf8ff,
  "pink200": #c84696,
  "pink100": #ff6eaa,
  "pink50": #ffbedc,
  "pink20": #ffe7f1,
  "pink10": #fff4fb,
  "red200": #b43c4f,
  "red100": #ff5064,
  "red50": #ffb4be,
  "red20": #ffdce6,
  "red10": #fff2f6,
  "orange200": #c76428,
  "orange100": #ffa064,
  "orange50": #ffdcbe,
  "orange20": #fff1e6,
  "orange10": #fffaf5,
  "yellow200": #c8ab50,
  "yellow100": #ffe68b,
  "yellow50": #fff4c7,
  "yellow20": #fffae6,
  "yellow10": #fffcf2,
  "green200": #64b43b,
  "green100": #a0f078,
  "green50": #dcffc8,
  "green20": #f0fae6,
  "green10": #f9fff2,
  "mint200": #64a996,
  "mint100": #82f0be,
  "mint50": #c8ffe6,
  "mint20": #e6fbf0,
  "mint10": #f1fff8,
  "teal200": #4696b4,
  "teal100": #82d2f0,
  "teal50": #c8f0ff,
  "teal20": #e5fafa,
  "teal10": #f2ffff,
  "blue200": #2864be,
  "blue100": #649fff,
  "blue50": #bedcff,
  "blue20": #dcf1ff,
  "blue10": #f1f9ff,
  "transparent": transparent,
  "facebook": #3975ea,
);

$primary200: map.get($colorMap, "primary200");
$primary100: map.get($colorMap, "primary100");
$primary50: map.get($colorMap, "primary50");
$primary20: map.get($colorMap, "primary20");
$primary10: map.get($colorMap, "primary10");

$secondary200: map.get($colorMap, "secondary200");
$secondary100: map.get($colorMap, "secondary100");
$secondary50: map.get($colorMap, "secondary50");
$secondary20: map.get($colorMap, "secondary20");
$secondary10: map.get($colorMap, "secondary10");

$black: map.get($colorMap, "black");
$grey100: map.get($colorMap, "grey100");
$grey50: map.get($colorMap, "grey50");
$grey20: map.get($colorMap, "grey20");
$white: map.get($colorMap, "white");

$lavender200: map.get($colorMap, "lavender200");
$lavender100: map.get($colorMap, "lavender100");
$lavender50: map.get($colorMap, "lavender50");
$lavender20: map.get($colorMap, "lavender20");
$lavender10: map.get($colorMap, "lavender10");

$purple200: map.get($colorMap, "purple200");
$purple100: map.get($colorMap, "purple100");
$purple50: map.get($colorMap, "purple50");
$purple20: map.get($colorMap, "purple20");
$purple10: map.get($colorMap, "purple10");

$pink200: map.get($colorMap, "pink200");
$pink100: map.get($colorMap, "pink100");
$pink50: map.get($colorMap, "pink50");
$pink20: map.get($colorMap, "pink20");
$pink10: map.get($colorMap, "pink10");

$red200: map.get($colorMap, "red200");
$red100: map.get($colorMap, "red100");
$red50: map.get($colorMap, "red50");
$red20: map.get($colorMap, "red20");
$red10: map.get($colorMap, "red10");

$orange200: map.get($colorMap, "orange200");
$orange100: map.get($colorMap, "orange100");
$orange50: map.get($colorMap, "orange50");
$orange20: map.get($colorMap, "orange20");
$orange10: map.get($colorMap, "orange10");

$yellow200: map.get($colorMap, "yellow200");
$yellow100: map.get($colorMap, "yellow100");
$yellow50: map.get($colorMap, "yellow50");
$yellow20: map.get($colorMap, "yellow20");
$yellow10: map.get($colorMap, "yellow10");

$green200: map.get($colorMap, "green200");
$green100: map.get($colorMap, "green100");
$green50: map.get($colorMap, "green50");
$green20: map.get($colorMap, "green20");
$green10: map.get($colorMap, "green10");

$mint200: map.get($colorMap, "mint200");
$mint100: map.get($colorMap, "mint100");
$mint50: map.get($colorMap, "mint50");
$mint20: map.get($colorMap, "mint20");
$mint10: map.get($colorMap, "mint10");

$teal200: map.get($colorMap, "teal200");
$teal100: map.get($colorMap, "teal100");
$teal50: map.get($colorMap, "teal50");
$teal20: map.get($colorMap, "teal20");
$teal10: map.get($colorMap, "teal10");

$blue200: map.get($colorMap, "blue200");
$blue100: map.get($colorMap, "blue100");
$blue50: map.get($colorMap, "blue50");
$blue20: map.get($colorMap, "blue20");
$blue10: map.get($colorMap, "blue10");

$transparent: map.get($colorMap, "transparent");
$facebook: map.get($colorMap, "facebook");

$gradiMap: (
  "gradi00": linear-gradient(180deg, $lavender50 0%, $purple50 100%),
  "gradi01": linear-gradient(180deg, $purple50 0%, $blue50 100%),
  "gradi02": linear-gradient(180deg, $pink50 0%, $purple50 100%),
  "gradi03": linear-gradient(180deg, $red50 0%, $pink50 100%),
  "gradi04": linear-gradient(180deg, $orange50 0%, $red50 100%),
  "gradi05": linear-gradient(180deg, $orange50 0%, $yellow50 100%),
  "gradi06": linear-gradient(180deg, $green50 0%, $yellow50 100%),
  "gradi07": linear-gradient(180deg, $mint50 0%, $green50 100%),
  "gradi08": linear-gradient(180deg, $teal50 0%, $mint50 100%),
  "gradi09": linear-gradient(180deg, $blue50 0%, $teal50 100%),
);

$gradi00: map.get($gradiMap, "gradi00");
$gradi01: map.get($gradiMap, "gradi01");
$gradi02: map.get($gradiMap, "gradi02");
$gradi03: map.get($gradiMap, "gradi03");
$gradi04: map.get($gradiMap, "gradi04");
$gradi05: map.get($gradiMap, "gradi05");
$gradi06: map.get($gradiMap, "gradi06");
$gradi07: map.get($gradiMap, "gradi07");
$gradi08: map.get($gradiMap, "gradi08");
$gradi09: map.get($gradiMap, "gradi09");

/* stylelint-disable */
:export {
  primary200: $primary200;
  primary100: $primary100;
  primary50: $primary50;
  primary20: $primary20;
  primary10: $primary10;
  secondary200: $secondary200;
  secondary100: $secondary100;
  secondary50: $secondary50;
  secondary20: $secondary20;
  secondary10: $secondary10;
  black: $black;
  grey100: $grey100;
  grey50: $grey50;
  grey20: $grey20;
  white: $white;
  lavender200: $lavender200;
  lavender100: $lavender100;
  lavender50: $lavender50;
  lavender20: $lavender20;
  lavender10: $lavender10;
  purple200: $purple200;
  purple100: $purple100;
  purple50: $purple50;
  purple20: $purple20;
  purple10: $purple10;
  pink200: $pink200;
  pink100: $pink100;
  pink50: $pink50;
  pink20: $pink20;
  pink10: $pink10;
  red200: $red200;
  red100: $red100;
  red50: $red50;
  red20: $red20;
  red10: $red10;
  orange200: $orange200;
  orange100: $orange100;
  orange50: $orange50;
  orange20: $orange20;
  orange10: $orange10;
  yellow200: $yellow200;
  yellow100: $yellow100;
  yellow50: $yellow50;
  yellow20: $yellow20;
  yellow10: $yellow10;
  green200: $green200;
  green100: $green100;
  green50: $green50;
  green20: $green20;
  green10: $green10;
  mint200: $mint200;
  mint100: $mint100;
  mint50: $mint50;
  mint20: $mint20;
  mint10: $mint10;
  teal200: $teal200;
  teal100: $teal100;
  teal50: $teal50;
  teal20: $teal20;
  teal10: $teal10;
  blue200: $blue200;
  blue100: $blue100;
  blue50: $blue50;
  blue20: $blue20;
  blue10: $blue10;
  transparent: $transparent;
  facebook : $facebook;
  gradi00: $gradi00;
  gradi01: $gradi01;
  gradi02: $gradi02;
  gradi03: $gradi03;
  gradi04: $gradi04;
  gradi05: $gradi05;
  gradi06: $gradi06;
  gradi07: $gradi07;
  gradi08: $gradi08;
  gradi09: $gradi09;
}
