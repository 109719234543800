@use "@styles/color.module.scss";
@use "@styles/mix.scss";
@use "@styles/gap.scss";

.signupModal {
  display: grid;
  gap: 8px;
  text-align: center;

  .snsButton {
    display: grid;
    grid-auto-flow: column;
    gap: 11px;
    align-items: center;
  }

  .termsAndPrivate {
    @include mix.hover;

    .link {
      color: color.$grey100;
    }
  }
}

.guideModal {
  display: grid;
  gap: 16px;

  .iconBox {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    border-radius: 16px;
    background: #faf8ff;

    .browserIcon {
      display: grid;

      .iconText {
        text-align: center;
      }
    }
  }

  .text {
    text-align: center;
  }
}
