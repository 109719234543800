// https://www.notion.so/wiredcompany/z-index-549af874ee684dfca4d374bc1a9b1715
$contentHeaderZIndex: 10;
$headerZIndex: 10;
$floatingButtonZIndex: 30;
$channelTalkZIndex: 40;
$fullModalZIndex: 60;
$modalZIndex: 60;
$toastZIndex: 90;

@function calculateLowerZIndex($zIndex, $amount) {
  @return $zIndex - $amount;
}

@mixin isLowerThan ($higherZIndex, $amount: 1) {
  z-index: calculateLowerZIndex($higherZIndex, $amount);
}

/* stylelint-disable */
:export {
  contentHeaderZIndex: $contentHeaderZIndex;
  headerZIndex: $headerZIndex;
  floatingButtonZIndex: $floatingButtonZIndex;
  channelTalkZIndex: $channelTalkZIndex;
  fullModalZIndex: $fullModalZIndex;
  modalZIndex: $modalZIndex;
  toastZIndex: $toastZIndex;
}
