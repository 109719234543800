@use "@styles/color.module.scss";
@use "@styles/variable.module.scss";
@use "@styles/mix.scss";

.alignContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.top {
  align-items: start;
}

.center {
  align-items: center;
}

.bottom {
  align-items: flex-end;
}

.desktop {
  @include mix.mediaMobile {
    align-items: flex-end;
  }

  @include mix.mediaDesktop {
    align-items: center;
  }
}

.container {
  padding: 16px;

  @include mix.mediaDesktop {
    width: variable.$appWidthWhenDesktop;
  }

  @include mix.mediaMobile {
    width: 100%;
  }
}

.contentContainer {
  height: fit-content;
  border-radius: 16px;
  background-color: color.$white;
}

.labelContainer {
  position: relative;
  padding: 16px;
  border-radius: 16px;
  background-color: color.$white;

  @include mix.flexCenter(
    $justifyContent: space-between
  );
}

.closeButton {
  @include mix.hover;
}

.contentsContainer {
  padding: 0 16px 16px 16px;
}
