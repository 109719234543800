@use "@styles/color.module.scss";
@use "@styles/mix.scss";
@use "@styles/gap.scss";
@use "@styles/variable.module.scss";

@mixin mediaMobileWrapper {
  @include mix.mediaMobile {
    width: 100%;
    min-height: 100vh;
    margin-top: unset;
    border: unset;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
  border-radius: 8px;

  @include mediaMobileWrapper;

  @include mix.mediaMobile {
    border-radius: 0;
  }
}

.noLayout {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  @include mediaMobileWrapper;
}
