@use "@styles/color.module.scss";
@use "@styles/mix.scss";

.container {
  display: grid;
  gap: 8px;
}

.labelContainer {
  @include mix.bodyRegular;
  @include mix.flexCenter(
    $justifyContent: space-between
  );

  .required {
    position: relative;
    text-align: end;

    &::after {
      @include mix.bodyBold;

      content: "*";
      position: absolute;
      top: -4px;
      right: -10px;
      color: color.$red100;
    }
  }

  .description {
    @include mix.bodyRegular;

    margin-left: 8px;
  }
}

.errors {
  @include mix.bodyRegular;

  display: grid;
  gap: 4px;
}

.gridRow {
  display: grid;
  grid-auto-flow: column;
  gap: 4px;
}
