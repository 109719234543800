@use "@styles/color.module.scss";
@use "@styles/mix.scss";

@mixin button($background, $color) {
  @include mix.hover;
  @include mix.buttonShadow8;
  @include mix.bodyBold;

  width: 100%;
  height: 48px;
  padding-right: 16px;
  padding-left: 16px;
  border: none;
  border-radius: 8px;
  background-color: $background;
  color: $color;

  :focus {
    outline: none;
  }

  :disabled {
    cursor: default;
  }
}

.default {
  @include button(
    $background: color.$black,
    $color: color.$white
  );
}

.defaultNoShadow {
  @include button(
    $background: color.$black,
    $color: color.$white
  );

  box-shadow: none;
}

.defaultTransparent {
  @include button(
    $background: color.$black,
    $color: color.$white
  );
  @include mix.transparentBlack;
}

.disabled {
  @include button(
    $background: color.$grey50,
    $color: color.$white
  );
  @include mix.hover(unset);
}

.disabledNoShadow {
  @include button(
    $background: color.$grey50,
    $color: color.$white
  );
  @include mix.hover(unset);

  box-shadow: none;
}

.inverse {
  @include button(
    $background: color.$white,
    $color: color.$black
  );
}

.inverseWeekNoShadow {
  @include button(
    $background: color.$white,
    $color: color.$grey100
  );

  box-shadow: none;
}

.alert {
  @include button(
    $background: color.$white,
    $color: color.$red100
  );
}

.alertNoShadow {
  @include button(
    $background: color.$white,
    $color: color.$red100
  );

  box-shadow: none;
}

.alertWeekNoShadow {
  @include button(
    $background: color.$grey20,
    $color: color.$red100
  );

  box-shadow: none;
}

.kakaoLogin {
  @include mix.flexCenter;
  @include button(
    $background: #FAE64D,
    $color: color.$black
  );

  &:first-of-type {
    margin-right: 11px;
  }
}

.naverLogin {
  @include mix.flexCenter;
  @include button(
    $background: #5AC466,
    $color: color.$white
  );

  &:first-of-type {
    margin-right: 11px;
  }
}

.googleLogin {
  @include mix.flexCenter;
  @include button(
    $background: color.$white,
    $color: color.$black
  );

  border: 1px solid color.$black;

  &:first-of-type {
    margin-right: 11px;
  }
}

.line {
  @include button(
    $background: color.$white,
    $color: color.$black
  );

  border: 1px solid color.$black;
}

.lineNoShadow {
  @include button(
    $background: color.$white,
    $color: color.$black
  );

  border: 1px solid color.$black;
  box-shadow: none;
}

.lineWeek {
  @include button(
    $background: color.$white,
    $color: color.$black
  );

  border: 1px solid color.$grey50;
}

.line:disabled,
.lineWeek:disabled,
.lineNoShadow:disabled {
  @include button(
    $background: color.$transparent,
    $color: color.$grey50
  );

  border: 1px solid color.$grey50;
  box-shadow: none;
}

.inverseNoShadow {
  @include button(
    $background: color.$white,
    $color: color.$black
  );

  box-shadow: none;
}

.facebook {
  @include button(
    $background: color.$facebook,
    $color: color.$white
  );

  box-shadow: none;
}

.sideMenu {
  @include button(
    $background: transparent,
    $color: color.$black
  );
  @include mix.transparentWhite;

  color: color.$black;
}

.instagram {
  @include mix.flexCenter;
  @include button(
    $background: transparent,
    $color: color.$white
  );

  background: linear-gradient(88.88deg, #6166e2 0%, #ec3f63 100%);
}

.small {
  @include mix.hover;
  @include mix.bodyBold;

  padding: 8px;
  border-radius: 8px;
  background-color: color.$black;
  color: color.$white;
}

.smallInverse {
  @include mix.buttonShadow8;
  @include mix.hover;
  @include mix.bodyBold;

  padding: 8px 0;
  color: color.$black;
}

.sizeSmall {
  padding-right: 30px;
  padding-left: 30px;
}

.dropdown {
  @include mix.flexSpaceBetween;
  @include button(
    $background: color.$white,
    $color: color.$black
  );
  @include mix.bodyRegular;

  border: 1px solid color.$grey50;
}

.purple100 {
  @include button(
    $background: color.$purple100,
    $color: color.$white
  );

  box-shadow: 0 8px 16px -8px #c878ff;
}

.smallOval {
  @include button(
    $background: color.$white,
    $color: color.$black
  );
  @include mix.captionRegular;

  width: fit-content;
  padding: 8px 16px;
  border: 1px solid color.$grey50;
  border-radius: 100px;
}

.smallOval:disabled {
  border-color: color.$grey20;
  color: color.$grey50;
}
