@use "@styles/variable.module.scss";
@use "sass:map";

// deprecated 👇👇👇 design0.2 이후로는 translucent를 사용해주세요
@mixin transparent($backgroundColor, $blur) {
  background-color: $backgroundColor;
  // backdrop-filter -> -webkit-backdrop-filter 순으로 프로퍼티 작성해야 모바일에서 동작함.
  /* stylelint-disable */
  backdrop-filter: blur($blur);
  -webkit-backdrop-filter: blur($blur);
}

$transparentMap: (
  "transparentBlack": (rgba(0, 0, 0, 0.64), 16px),
  "transparentTooltip": (rgba(0, 0, 0, 0.64), 64px),
  "transparentGrey": (rgba(246, 246, 246, 0.72), 64px),
  "transparentWhite": (rgba(255, 255, 255, 0.72), 64px),
);

@mixin transparentBlack {
  @include transparent(map.get($transparentMap, "transparentBlack")...);
}

@mixin transparentTooltip {
  @include transparent(map.get($transparentMap, "transparentTooltip")...);
}

@mixin transparentGrey {
  @include transparent(map.get($transparentMap, "transparentGrey")...);
}

@mixin transparentWhite {
  @include transparent(map.get($transparentMap, "transparentWhite")...);
}
// deprecated 👆👆👆 design0.2 이후로는 translucent를 사용해주세요

@mixin translucent($backgroundColor, $blur) {
  background-color: $backgroundColor;
  // backdrop-filter -> -webkit-backdrop-filter 순으로 프로퍼티 작성해야 모바일에서 동작함.
  backdrop-filter: blur($blur);
  -webkit-backdrop-filter: blur($blur);
}

$translucentMap: (
  "translucentPrimary": (rgba(120, 86, 255, 0.8), 8px),
  "translucentBlack": (rgba(0, 0, 0, 0.64), 8px),
  "translucentGrey": (rgba(246, 246, 246, 0.72), 32px),
  "translucentWhite": (rgba(255, 255, 255, 0.72), 32px),
);

@mixin translucentPrimary {
  @include translucent(map.get($translucentMap, "translucentPrimary")...);
}

@mixin translucentBlack {
  @include translucent(map.get($translucentMap, "translucentBlack")...);
}

@mixin translucentGrey {
  @include translucent(map.get($translucentMap, "translucentGrey")...);
}

@mixin translucentWhite {
  @include translucent(map.get($translucentMap, "translucentWhite")...);
}


@mixin font($size, $weight, $height) {
  font-size: $size;
  font-weight: $weight;
  line-height: $height;
}

$fontMap: (
  "heading1": (32px, 900, 38px),
  "heading2": (28px, 900, 32px),
  "heading3": (24px, 800, 28px),
  "titleBold": (20px, 800, 24px),
  "titleRegular": (20px, 400, 24px),
  "subtitleBold": (16px, 700, 20px),
  "subtitleRegular": (16px, 400, 20px),
  "bodyBold": (14px, 700, 18px),
  "bodyRegular": (14px, 400, 18px),
  "captionBold": (12px, 700, 16px),
  "captionRegular": (12px, 400, 16px),
  "labelBold": (10px, 700, 12px),
  "labelRegular": (10px, 400, 12px),
);

@mixin heading1 {
  @include font(map.get($fontMap, "heading1")...);
}

@mixin heading2 {
  @include font(map.get($fontMap, "heading2")...);
}

@mixin heading3 {
  @include font(map.get($fontMap, "heading3")...);
}

@mixin titleBold {
  @include font(map.get($fontMap, "titleBold")...);
}

@mixin titleRegular {
  @include font(map.get($fontMap, "titleRegular")...);
}

@mixin subtitleBold {
  @include font(map.get($fontMap, "subtitleBold")...);
}

@mixin subtitleRegular {
  @include font(map.get($fontMap, "subtitleRegular")...);
}

@mixin bodyBold {
  @include font(map.get($fontMap, "bodyBold")...);
}

@mixin bodyRegular {
  @include font(map.get($fontMap, "bodyRegular")...);
}

@mixin captionBold {
  @include font(map.get($fontMap, "captionBold")...);
}

@mixin captionRegular {
  @include font(map.get($fontMap, "captionRegular")...);
}

@mixin labelBold {
  @include font(map.get($fontMap, "labelBold")...);
}

@mixin labelRegular {
  @include font(map.get($fontMap, "labelRegular")...);
}

@mixin shadow($offsetX, $offsetY, $blurRadius, $spreadRadius, $color) {
  box-shadow: $offsetX $offsetY $blurRadius $spreadRadius $color;
}

$shadowMap: (
  "boxShadow4": (0, 0, 24px, 0, rgba(0, 0, 0, 0.04)),
  "boxShadow8": (0, 0, 24px, 0, rgba(0, 0, 0, 0.08)),
  "boxShadow16": (0, 0, 24px, 0, rgba(0, 0, 0, 0.16)),
  "boxShadow32": (0, 0, 24px, 0, rgba(0, 0, 0, 0.32)),
  "buttonShadow8": (0, 8px, 32px, -8px, rgba(0, 0, 0, 0.08)),
  "buttonShadow16": (0, 8px, 32px, -8px, rgba(0, 0, 0, 0.16)),
);

@mixin boxShadow4 {
  @include shadow(map.get($shadowMap, "boxShadow4")...);
}

@mixin boxShadow8 {
  @include shadow(map.get($shadowMap, "boxShadow8")...);
}

@mixin boxShadow16 {
  @include shadow(map.get($shadowMap, "boxShadow16")...);
}

@mixin boxShadow32 {
  @include shadow(map.get($shadowMap, "boxShadow32")...);
}

@mixin buttonShadow8 {
  @include shadow(map.get($shadowMap, "buttonShadow8")...);
}

@mixin buttonShadow16 {
  @include shadow(map.get($shadowMap, "buttonShadow16")...);
}

// utility style
@mixin hover($type: pointer) {
  &:hover {
    cursor: $type;

    @content;
  }
}

@mixin flexCenter($direction: row, $alignItems: center, $justifyContent: center) {
  display: flex;
  flex-direction: $direction;
  align-items: $alignItems;
  justify-content: $justifyContent;
}

@mixin flexColumn($direction: column, $alignItems: center, $justifyContent: center) {
  display: flex;
  flex-direction: $direction;
  align-items: $alignItems;
  justify-content: $justifyContent;
}

@mixin flexSpaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin ellipsis($line: 2, $height: 36px) {
  display: -webkit-box !important;
  max-height: $height;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

@mixin mediaMobile {
  @media (max-width: variable.$mediaMobileWidth) {
    @content;
  }
}

@mixin mediaDesktop {
  @media (min-width: calc(#{variable.$mediaMobileWidth} + 1px)) {
    @content;
  }
}

@mixin mediaHorizontal {
  @media (max-height: #{variable.$appHeightWhenDesktop}) {
    @content;
  }
}

@mixin gridColumn($gap: 10px) {
  display: grid;
  grid-auto-flow: column;
  gap: $gap;
  align-items: center;
}

@mixin gridRow($gap: 10px) {
  display: grid;
  grid-auto-flow: row;
  gap: $gap;
  align-items: center;
}

@mixin isDraggingShadow {
  @include boxShadow8;
}

@mixin noTapHilightColor {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
