@use "@styles/color.module.scss";
@use "@styles/variable.module.scss";
@use "@styles/mix.scss";

// if add new gap, should update @global/types.ts Gap type.
// should update both gap, margin-bottom and margin-right.

.mb0 { margin-bottom: 0; }
.mb4 { margin-bottom: 4px; }
.mb8 { margin-bottom: 8px; }
.mb12 { margin-bottom: 12px; }
.mb16 { margin-bottom: 16px; }
.mb20 { margin-bottom: 20px; }
.mb24 { margin-bottom: 24px; }
.mb32 { margin-bottom: 32px; }
.mb48 { margin-bottom: 48px; }
.mb64 { margin-bottom: 64px; }

.mr0 { margin-right: 0; }
.mr4 { margin-right: 4px; }
.mr8 { margin-right: 8px; }
.mr10 { margin-right: 10px; }
.mr12 { margin-right: 12px; }
.mr16 { margin-right: 16px; }
.mr20 { margin-right: 20px; }
.mr24 { margin-right: 24px; }
.mr64 { margin-right: 64px; }

.mt4 { margin-top: 4px; }
.mt8 { margin-top: 8px; }
.mt16 { margin-top: 16px; }
.mt24 { margin-top: 24px; }

.grid-row-64 {
  display: grid;
  grid-auto-flow: row;
  gap: 64px;
}

.grid-row-24 {
  display: grid;
  grid-auto-flow: row;
  gap: 24px;
}

.grid-row-16 {
  display: grid;
  grid-auto-flow: row;
  gap: 16px;
}

.grid-row-8 {
  display: grid;
  grid-auto-flow: row;
  gap: 8px;
}

.grid-row-4 {
  display: grid;
  grid-auto-flow: row;
  gap: 4px;
}

.pd16 {
  padding: 16px;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

$contentBoxMinHeight: calc(100vh - #{variable.$headerHeight});
