@use "@styles/color.module.scss";
@use "@styles/mix.scss";
@use "@styles/gap.scss";
@use "@styles/variable.module.scss";

:root {
  // FloorModal의 컨텐츠영역의 max-height은 화면전체높이에서
  // 바닥에 깔린 화면의 헤더높이와 FloorModal의 헤더높이를 제외한 값입니다
  --modal-content-max-height: calc(100vh - #{variable.$headerHeight} * 2);
  --app-height: 100%;
}

* {
  box-sizing: border-box;
  font-family: Pretendard, "Nanum Gothic", sans-serif;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

#__next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  font-family: Pretendard, "Nanum Gothic", sans-serif;

  @include mix.mediaHorizontal {
    align-items: unset;
  }
}

a {
  outline: none;
  color: inherit;
  text-decoration: none;
}

a:hover,
a:active {
  text-decoration: none;
}

svg {
  vertical-align: bottom;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  color: color.$black;
}
