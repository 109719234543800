@use "@styles/color.module.scss";
@use "@styles/mix.scss";

.container {
  @include mix.bodyRegular;
  @include mix.hover(text);
  @include mix.flexCenter;

  position: relative;
  width: 100%;
  height: 48px;
  padding: 16px;
  overflow: hidden;
  transition: border-color 0.3s;
  border: 1px solid color.$grey50;
  border-radius: 8px;
  background: color.$white;

  &.disabled {
    border: none;
    background: color.$grey20;
  }

  &.disabledBorder {
    border: 1px solid color.$grey50;
  }
}

.focus {
  border-color: color.$black;
}

.error {
  border-color: color.$red100;
}

.fixedPlaceholder {
  @include mix.flexCenter;

  height: 50px;
  white-space: nowrap;
}

.leftIcon {
  margin-right: 8px;
}

.inputWrapper {
  position: relative;
  flex: 1;
  width: 100%;
  height: 50px;
  overflow: hidden;
}

.input {
  @include mix.flexCenter();

  position: absolute;
  width: 108%;
  height: 50px;
  padding-left: 0;
  transform: scale(0.875);
  transform-origin: 0 50%;
  border: none;
  outline: none;
  background: color.$white;
  font-size: 16px;

  &:disabled {
    background: color.$grey20;
  }

  &::placeholder {
    color: color.$grey100;
  }

  &.right {
    padding-right: 8px;
    text-align: right;
  }
}

.suffix {
  @include mix.flexCenter;

  position: absolute;
  right: 0;
  height: 50px;
}

.rightIcon {
  @include mix.hover;

  flex-shrink: 0;
}
