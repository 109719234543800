@use "@styles/color.module.scss";
@use "@styles/mix.scss";
@use "@styles/variable.module.scss";
@use "@styles/zIndex.module.scss";

.container {
  @include mix.flexCenter(
    $direction: column,
    $justifyContent: space-between
  );

  position: fixed;
  z-index: zIndex.$toastZIndex;
  bottom: 0;
  width: #{variable.$appWidthWhenDesktop};
  padding-bottom: 16px;
  overflow: hidden;
  transition: height 0.3s;
  gap: 8px;

  @include mix.mediaMobile {
    width: 100%;
  }
}

.toast {
  @include mix.transparentBlack;
  @include mix.hover;
  @include mix.flexCenter(
    $justifyContent: flex-start
  );

  width: calc(100% - 32px);
  min-height: 48px;
  margin: 0 16px 0 16px;
  padding: 12px;
  transition: opacity #{variable.$toastTransitionCount}s;
  border-radius: 8px;
  opacity: 0;

  .checkIcon {
    margin-right: 8px;
  }

  .text {
    @include mix.flexCenter;
  }

  &.visible {
    opacity: 1;
  }

  &.invisible {
    display: none;
  }
}
